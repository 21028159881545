import Vue from "vue";
import App from "./App.vue";
import 'fullpage.js/vendors/scrolloverflow'; 
import VueFullPage from "vue-fullpage.js";
import "fullpage.js/dist/fullpage.css";
import Carousel from "ant-design-vue/lib/carousel";
import "ant-design-vue/lib/carousel/style";
import VueLazyload from 'vue-lazyload'
import router from './router'
import './assets/font/font.css'
Vue.use(VueLazyload)

Vue.use(Carousel);
Vue.config.productionTip = false;
Vue.use(VueFullPage);
new Vue({
  router:router,
  render: (h) => h(App),
}).$mount("#app");
