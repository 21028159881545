import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const routes = [
  { path: "/", name: "home", component: () => import("../view/home.vue") },
  {
    path: "/zhengwu",
    name:'zhengwu',
    component: () => import("../components/zhengwu.vue"),
  },
  {
    path: "/mobileZhengwu",
    name:'mobileZhengwu',
    component: () => import("../components/mobile/mobileZhengwu.vue"),
  },
];

export default new Router({
  mode: "history",
  routes,
});
